import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import './App.css'

import About from './pages/about/About'
import Landing from './pages/landing/Landing'
import ROUTES from './utils/routes'

const router = createBrowserRouter([
  {
    path: ROUTES.home,
    element: <Landing />
  },
  {
    path: ROUTES.about,
    element: <About />
  }
])

const App = () => {
  return (
    <div className="app">
      <div className="app-background">
        <RouterProvider
          router={router}
          fallbackElement={<div>loading</div>}
          future={{ v7_startTransition: true }}
        />
      </div>
    </div>
  )
}

export default App
