import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import './Header.css'

import Menu from './Menu'
import MenuMobile from './Menu/Menu-Mobile'
import { ScreenSize, useScreenSize } from '../../hooks/screen-size'

import { WIDTH_L_MOBILE } from '../../utils/constants'

import ROUTES from '../../utils/routes'
import { LOGO } from '../../images'

const Header = () => {
  const [screenSize, setScreenSize] = useState<ScreenSize>({
    width: window.innerWidth,
    height: window.innerHeight
  })

  useScreenSize(screenSize, setScreenSize)

  return (
    <div className="header">
      <Link to={ROUTES.home} className="header__logo">
        <img src={LOGO} alt="Gastando Rollos logo" className="header__logo__image" />
      </Link>
      <div className="header__menu-container">
        {screenSize.width <= WIDTH_L_MOBILE ? <MenuMobile /> : <Menu />}
      </div>
    </div>
  )
}

export default Header
