import React from 'react'

type IconInstagramProps = {
  className?: string | Array<string>
}

const IconInstagram = ({ className }: IconInstagramProps) => {
  const classNames =
    typeof className === 'string' || !className ? className : [...className].join(' ')

  return (
    <svg
      className={classNames}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="512.000000pt"
      height="512.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M1230 5113 c-346 -56 -583 -175 -816 -407 -167 -167 -270 -330 -342
-541 -71 -208 -67 -111 -67 -1605 0 -1494 -4 -1397 67 -1605 72 -211 175 -374
342 -541 167 -167 330 -270 541 -342 208 -71 111 -67 1605 -67 1494 0 1397 -4
1605 67 211 72 374 175 541 342 167 167 270 330 342 541 71 208 67 111 67
1605 0 1494 4 1397 -67 1605 -72 211 -175 374 -342 541 -167 167 -330 270
-541 342 -209 71 -94 66 -1575 68 -734 1 -1346 0 -1360 -3z m2658 -473 c195
-39 331 -111 475 -249 150 -143 236 -299 277 -503 19 -93 20 -141 20 -1328 0
-1187 -1 -1235 -20 -1328 -41 -204 -127 -360 -277 -503 -144 -138 -280 -210
-475 -249 -93 -19 -141 -20 -1328 -20 -1187 0 -1235 1 -1328 20 -195 39 -331
111 -475 249 -150 143 -236 299 -277 503 -19 93 -20 141 -20 1328 0 1187 1
1235 20 1328 41 204 127 360 277 503 143 137 279 209 471 249 82 17 165 19
1325 19 1195 1 1242 0 1335 -19z"
        />
        <path
          d="M3849 4257 c-107 -31 -192 -107 -230 -206 -29 -77 -24 -194 12 -265
125 -254 488 -252 607 2 23 49 27 70 27 147 0 101 -17 149 -80 223 -73 85
-226 130 -336 99z"
        />
        <path
          d="M2331 3864 c-112 -19 -277 -75 -378 -129 -269 -143 -453 -332 -583
-600 -100 -207 -125 -322 -125 -575 0 -253 25 -368 125 -575 136 -280 335
-479 615 -615 207 -100 322 -125 575 -125 253 0 368 25 575 125 171 83 301
181 417 315 89 102 137 175 198 300 100 207 125 322 125 575 0 194 -12 281
-57 415 -27 81 -108 247 -155 317 -203 303 -530 515 -883 573 -117 18 -334 18
-449 -1z m447 -474 c305 -86 526 -308 614 -617 32 -112 32 -314 0 -426 -89
-311 -308 -530 -619 -619 -112 -32 -314 -32 -426 0 -311 89 -530 308 -619 619
-32 112 -32 314 0 426 97 339 355 574 700 637 78 15 268 4 350 -20z"
        />
      </g>
    </svg>
  )
}

export default IconInstagram
