const TEXTS = {
  copyright: 'Copyright © 2024 Gastando Rollos by Ange Pabón P – All rights reserved',
  cominSoon: 'Coming Soon',
  about: {
    paragraph1:
      'Ange captures moments as they are, driven by a passion for authentic storytelling through photography. Inspired by the raw beauty of landscapes and the diverse tapestry of cultures around the globe, she embarks on a journey to freeze time and preserve the essence of intriguing destinations.',
    paragraph2:
      'Each scene she chooses to photograph is selected by her fascination with the untouched wonders of nature or the resilient spirit of cities rebuilding from the scars of history. With her pictures, she seeks to convey the emotions, stories, and rich history embedded in every frame.',
    paragraph3:
      'Believing in the power of authenticity, she captures moments as they unfold, focusing on intricate details to provide viewers with an intimate glimpse into the reality experienced by those who inhabit these places. Her philosophy is simple yet profound: "If it evokes emotion, capture it."',
    paragraph4:
      "Ange aims to invite viewers to confront the reality of the world through her photography, encouraging them to empathize with the joys and struggles of those depicted. It's not just about aesthetics—it's about fostering empathy and understanding.",
    paragraph5:
      'Join Ange on this captivating journey as she explores the world, embracing the beauty, history, and authenticity of each moment captured.',
    email: 'gastandorollos@gmail.com'
  }
}

export default TEXTS
