import React from 'react'

import './SocialMedia.css'

import { IconInstagram } from '../Icon'
import { RRSS } from '../../utils/constants'

type InstagramProps = {
  id?: string
  className?: string
}

const Instagram = ({ id, className }: InstagramProps) => (
  <a
    id={id}
    href={RRSS.instagram}
    target="_blank"
    className={`rrss-item ${className}`}
    rel="noreferrer"
  >
    <IconInstagram className="rrss-item__icon" />
  </a>
)

export default Instagram
