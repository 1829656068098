const IMAGE_BUDAPEST_BUDA_CASTLE = 'images/budapest/landspace-buda-castle.jpg'
const IMAGE_BERLIN_CHARLOTTENBURG_PALACE = 'images/berlin/portrait-charlottenburg-palace.jpg'
const IMAGE_HAMBURG_ALMACENES = 'images/hamburg/portrait-almacenes.jpg'
const IMAGE_SCHWERIN_PALACE = 'images/schwerin/landscape-schwerin-palace.jpg'
const IMAGE_NEW_YORK_EMPIRE_STATE_VIEW = 'images/new-york/landscape-view-empire-state.jpg'
const IMAGE_SAINT_STEPHEN_CHURCH = 'images/budapest/portrait-san-stephen-church.jpg'
const IMAGE_BUDAPEST_SHOES = 'images/budapest/landscape-shoes-budapest.jpg'
const IMAGE_MARRAKESH = 'images/morocco/portrait-marrakesh.jpg'
const IMAGE_BUDAPEST_SHOES_PHOTO = 'images/budapest/landscape-busdapest-photo.jpg'
const LOGO = 'images/logo.png'

export {
  LOGO,
  IMAGE_BUDAPEST_BUDA_CASTLE,
  IMAGE_BERLIN_CHARLOTTENBURG_PALACE,
  IMAGE_HAMBURG_ALMACENES,
  IMAGE_SCHWERIN_PALACE,
  IMAGE_SAINT_STEPHEN_CHURCH,
  IMAGE_NEW_YORK_EMPIRE_STATE_VIEW,
  IMAGE_BUDAPEST_SHOES,
  IMAGE_MARRAKESH,
  IMAGE_BUDAPEST_SHOES_PHOTO
}
