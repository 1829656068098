import React, { useState } from 'react'

import './Landing.css'

import Page from '../../components/Page'
import { ScreenSize, useScreenSize } from '../../hooks/screen-size'
import {
  IMAGE_BERLIN_CHARLOTTENBURG_PALACE,
  IMAGE_BUDAPEST_BUDA_CASTLE,
  IMAGE_HAMBURG_ALMACENES,
  IMAGE_SCHWERIN_PALACE
} from '../../images'

const Landing = () => {
  const [screenSize, setScreenSize] = useState<ScreenSize>({
    width: window.innerWidth,
    height: window.innerHeight
  })

  useScreenSize(screenSize, setScreenSize)

  return (
    <Page>
      <div className="landing">
        <div className="image-container">
          <img
            src={IMAGE_BUDAPEST_BUDA_CASTLE}
            className="landing__image"
            alt="gastando rollos landing"
          />
        </div>
        <div className="landing__image-container">
          <div className="image-container">
            <img
              src={IMAGE_BERLIN_CHARLOTTENBURG_PALACE}
              className="landing__image"
              alt="gastando rollos landing"
            />
          </div>
          <div className="image-container">
            <img
              src={IMAGE_HAMBURG_ALMACENES}
              className="landing__image"
              alt="gastando rollos landing"
            />
          </div>
        </div>
        <div className="image-container">
          <img
            src={IMAGE_SCHWERIN_PALACE}
            className="landing__image"
            alt="gastando rollos landing"
          />
        </div>
      </div>
    </Page>
  )
}

export default Landing
