import React from 'react'

import './Footer.css'

import TEXTS from '../../utils/text'
import { Instagram } from '../SocialMedia'

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__rrss">
        <div className="footer__rrss-icon">
          <Instagram id="icon-footer-ig" />
        </div>
      </div>
      <div className="footer__copyright">
        <span>{TEXTS.copyright}</span>
      </div>
    </div>
  )
}

export default Footer
