import { Dispatch, SetStateAction, useEffect } from 'react'

export type ScreenSize = {
  width: number
  height: number
}

export const useScreenSize = (
  screenSize: ScreenSize,
  setScreenSize: Dispatch<SetStateAction<ScreenSize>>
) => {
  useEffect(() => {
    const updateDimension = ({ target }: any) => {
      setScreenSize({ width: target.innerWidth, height: target.innerHeight })
    }

    window.addEventListener('resize', updateDimension)

    return () => {
      window.removeEventListener('resize', updateDimension)
    }
  }, [screenSize])
}
