import { useEffect, useState } from 'react'

import './Menu.css'

import Menu from '.'

const MenuMobile = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false)

  const handleShowMenu = () => {
    setShowMenu(!showMenu)
  }

  useEffect(() => {
    const handleClickOutside = ({ target }: any) => {
      if (target.id === 'header-menu-list-background') {
        setShowMenu(false)
      }
    }

    window.addEventListener('click', handleClickOutside)

    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <>
      <span
        className="header__menu-list-icon material-icons-round xlarge clicable"
        onClick={handleShowMenu}
      >
        menu
      </span>
      {showMenu && (
        <>
          <div id="header-menu-list-background" className="header__menu-list__background" />
          <div className="header__menu-list">
            <Menu />
          </div>
        </>
      )}
    </>
  )
}

export default MenuMobile
