import React from 'react'

import './About.css'
import Page from '../../components/Page'
import TEXTS from '../../utils/text'

import {
  IMAGE_BUDAPEST_SHOES,
  IMAGE_BUDAPEST_SHOES_PHOTO,
  IMAGE_MARRAKESH,
  IMAGE_NEW_YORK_EMPIRE_STATE_VIEW,
  IMAGE_SAINT_STEPHEN_CHURCH
} from '../../images'

const About = () => {
  return (
    <Page>
      <div className="about">
        <div className="about__title mobile">
          <h1>ABOUT</h1>
        </div>
        <div className="about__section portrait reverse">
          <div className="about__section__texts">
            <div className="about__title desktop">
              <h1>ABOUT</h1>
            </div>
            <div className="about__section__text">
              <p>{TEXTS.about.paragraph1}</p>
            </div>
          </div>
          <div className="about__section__image">
            <img src={IMAGE_SAINT_STEPHEN_CHURCH} alt="gastando rollos about" />
          </div>
        </div>
        <div className="about__section">
          <div className="about__section__text">
            <p>{TEXTS.about.paragraph2}</p>
          </div>
          <div className="about__section__image">
            <img src={IMAGE_NEW_YORK_EMPIRE_STATE_VIEW} alt="gastando rollos about" />
          </div>
        </div>
        <div className="about__section reverse">
          <div className="about__section__text">
            <p>{TEXTS.about.paragraph3}</p>
          </div>
          <div className="about__section__image">
            <img src={IMAGE_BUDAPEST_SHOES_PHOTO} alt="gastando rollos about" />
          </div>
        </div>
        <div className="about__section portrait">
          <div className="about__section__text">
            <p>{TEXTS.about.paragraph4}</p>
          </div>
          <div className="about__section__image">
            <img src={IMAGE_MARRAKESH} alt="gastando rollos about" />
          </div>
        </div>
        <div className="about__section reverse">
          <div className="about__section__text">
            <p>{TEXTS.about.paragraph5}</p>
          </div>
          <div className="about__section__image">
            <img src={IMAGE_BUDAPEST_SHOES} alt="gastando rollos about" />
          </div>
        </div>
      </div>
    </Page>
  )
}

export default About
